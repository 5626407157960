<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :permissionKey='"real-estate-developer-project"'
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate-developer-project',
      singularName: 'real-estate-developer-project',
      searchFields: {
        name: null,
        status: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 1,
          name: this.$t('statuses.active'),
        },
        {
          id: 0,
          name: this.$t('statuses.inactive'),
        },
      ]
    },
  },
}
</script>
